import React, { useEffect, useState } from 'react'
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import '../assets/css/style.css';
import Api from '../common/Api';
import { enCreption, decryptData } from '../common/Aes';
import { Icons, ToastContainer, toast } from 'react-toastify';
import ToastObj from '../common/ToastObj';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';
import Navigation from '../componants/Navigation';

export default function Transactions() {
  const { authData, logout } = useAuth();
  const auth_user = decryptData(authData);
  const navigate = useNavigate();
  // Example data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [clearFilter, setClearFilter] = useState(false);
  const itemsPerPage = 25;

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('1');

  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    fetchPageData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchPageData(0);
  }, [status]);

  useEffect(() => {
    if (clearFilter)
      fetchPageData(0);
    return setClearFilter(false);
  }, [search]);

  const fetchPageData = async (page) => {
    try {
      const payload = await enCreption({ vendor_id: auth_user.id, page: page + 1, limit: itemsPerPage, search: search, status: status });
      await Api.post(`customers`, payload)
        .then(res => {
          const response = res.data;
          setLoading(false);
          if (response.status === "success") {
            setData(response.items);
            setTotalPages(response.totalPages);
          } else if (response.status === "error") {
            toast.error(response.msg, ToastObj);
          } else {
            toast.error("Something went wrong !!!", ToastObj);
          }
        });
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchFilter = () => {
    if (search === "" || search === null) {
      Swal.fire({ icon: "error", text: 'Please enter text to search customer.', allowOutsideClick: false });
      return;
    }
    setLoading(true);
    fetchPageData(0); // Reset to first page when filtering
  };

  const handleClearFilter = () => {
    setClearFilter(true);
    setSearch("");
    setStatus('1');
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };

  // ADD OR EDIT CUATOMESRS //

  const handleAddCustomer = async (event, edit = null) => {
    event.preventDefault();
    let formData = { id: null, username: '', name: '', email: '', mobile: '', status: '1' };
    if (edit != null)
      formData = edit;

    Swal.fire({
      title: (edit != null) ? 'Edit Customer' : 'Add Customer',
      width: '60%',
      html: `
        <div class="container-fluid swal-form" style="text-align:left !important">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="username" class="form-label">Username *</label>
            <input type="text" id="username" value="${formData.username}" class="form-control" placeholder="Enter Username">
          </div>
          <div class="col-md-6 mb-3">
            <label for="password" class="form-label">Password ${(formData.id) ? '' : '*'}</label>
            <input type="password" id="password" class="form-control" placeholder="Enter Password">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="name" class="form-label">Name *</label>
            <input type="text" id="name" value="${formData.name}" class="form-control" placeholder="Enter Name">
          </div>
          <div class="col-md-6 mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" id="email" value="${formData.email}" class="form-control" placeholder="Enter Email">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="mobile" class="form-label">Mobile *</label>
            <input type="tel" id="mobile" value="${formData.mobile}" class="form-control" placeholder="Enter Mobile">
          </div>
          <div class="col-md-6 mb-3">
            <label for="status" class="form-label">Status *</label>
            <select id="status" class="form-control">
              <option value="1" ${formData.status === 'Active' ? 'selected' : ''} >Active</option>
              <option value="0" ${formData.status === 'Deactive' ? 'selected' : ''}>Deactive</option>
            </select>
          </div>
        </div>
      </div>
      `,
      confirmButtonText: 'Submit',
      showCancelButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const username = Swal.getPopup().querySelector('#username').value;
        const password = Swal.getPopup().querySelector('#password').value;
        const name = Swal.getPopup().querySelector('#name').value;
        const email = Swal.getPopup().querySelector('#email').value;
        const mobile = Swal.getPopup().querySelector('#mobile').value;
        const status = Swal.getPopup().querySelector('#status').value;
        const cust_id = formData.id;
        const vendor_id = auth_user.id;

        if (!username || !name || !mobile || !status) {
          if (cust_id == null && !password) {
            Swal.showValidationMessage('Please fill out all mandatory fields.');
            return false;
          }

          if (cust_id != null) {
            Swal.showValidationMessage('Please fill out mandatory fields');
            return false;
          }
        }

        if (cust_id == null || (cust_id != null && password != "" && password.length < 8)) {
          // New customer, check both username and password length
          if (username.length < 8 || password.length < 8) {
            if (username.length < 8 && password.length < 8) {
              Swal.showValidationMessage('Please enter a username and password with a minimum of 8 characters each.');
            } else if (username.length < 8) {
              Swal.showValidationMessage('Please enter a username with a minimum of 8 characters.');
            } else if (password.length < 8) {
              Swal.showValidationMessage('Please enter a password with a minimum of 8 characters.');
            }
            return false;
          }
        } else {
          // Existing customer, only check username length
          if (username.length < 8) {
            Swal.showValidationMessage('Please enter a username with a minimum of 8 characters.');
            return false;
          }
        }

        // Email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email && !emailRegex.test(email)) {
          Swal.showValidationMessage('Please enter a valid email');
          return false;
        }

        // Mobile validation (numeric, 10 digits)
        const mobileRegex = /^[0-9]{10}$/;
        if (!mobileRegex.test(mobile)) {
          Swal.showValidationMessage('Please enter a valid 10-digit mobile number');
          return false;
        }

        return { cust_id, vendor_id, username, password, name, email, mobile, status };
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = await enCreption(result.value);
          await Api.post(`addCustomer`, payload)
            .then(res => {
              const response = res.data;
              if (response.status === "success") {
                fetchPageData(currentPage);
                Swal.fire({
                  icon: 'success',
                  title: 'Success !!!',
                  text: response.msg
                });
              } else if (response.status === "error") {
                Swal.fire({
                  icon: 'error',
                  title: 'Error !!!',
                  text: response.msg
                });
              } else {
                toast.error("Something went wrong !!!", ToastObj);
              }
            });
        } catch (error) {
          toast.error("Something went wrong !!!", ToastObj);
          console.error('Error Creating Customer:', error);
        }
      }
    });
  }

  const handleAddBalance = (event, item) => {
    event.preventDefault();
    Swal.fire({
      title: 'Add Balance',
      width: '60%',
      html: `
        <div class="container-fluid swal-form" style="text-align:left !important">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="username" class="form-label">Username</label>
            <input type="text" id="username" value="${item.username}" disabled="disabled" class="form-control" placeholder="Enter Username">
          </div>
          <div class="col-md-6 mb-3">
            <label for="balance" class="form-label">Balance *</label>
            <input type="balance" id="balance" class="form-control" placeholder="Enter Balance">
          </div>
        </div>
      </div>
      `,
      confirmButtonText: 'Submit',
      showCancelButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const balance = Swal.getPopup().querySelector('#balance').value;
        const cust_id = item.id;
        const vendor_id = auth_user.id;
        // Balance validation (numeric)
        const Regex = /^[1-9][0-9]*$/;
        if (!Regex.test(balance)) {
          Swal.showValidationMessage('Please enter a valid number.');
          return false;
        }
        return { cust_id, vendor_id, balance };
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = await enCreption(result.value);
          await Api.post(`addBalance`, payload)
            .then(res => {
              const response = res.data;
              if (response.status === "success") {
                fetchPageData(currentPage);
                Swal.fire({
                  icon: 'success',
                  title: 'Success !!!',
                  text: response.msg
                });
              } else if (response.status === "error") {
                Swal.fire({
                  icon: 'error',
                  title: 'Error !!!',
                  text: response.msg
                });
              } else {
                toast.error("Something went wrong !!!", ToastObj);
              }
            });
        } catch (error) {
          toast.error("Something went wrong !!!", ToastObj);
          console.error('Error Creating Balance:', error);
        }
      }
    });
  }

  const handleReturnBalance = (event, item) => {
    event.preventDefault();
    Swal.fire({
      title: 'Return Balance',
      width: '60%',
      html: `
        <div class="container-fluid swal-form" style="text-align:left !important">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="username" class="form-label">Username</label>
            <input type="text" id="username" value="${item.username}" disabled="disabled" class="form-control" placeholder="Enter Username">
          </div>
          <div class="col-md-6 mb-3">
            <label for="balance" class="form-label">Balance *</label>
            <input type="balance" id="balance" class="form-control" placeholder="Enter Balance">
          </div>
        </div>
      </div>
      `,
      confirmButtonText: 'Submit',
      showCancelButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const balance = Swal.getPopup().querySelector('#balance').value;
        const cust_id = item.id;
        const vendor_id = auth_user.id;
        // Balance validation (numeric)
        const Regex = /^[1-9][0-9]*$/;
        if (!Regex.test(balance)) {
          Swal.showValidationMessage('Please enter a valid number.');
          return false;
        }
        return { cust_id, vendor_id, balance };
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = await enCreption(result.value);
          await Api.post(`returnBalance`, payload)
            .then(res => {
              const response = res.data;
              if (response.status === "success") {
                fetchPageData(currentPage);
                Swal.fire({
                  icon: 'success',
                  title: 'Success !!!',
                  text: response.msg
                });
              } else if (response.status === "error") {
                Swal.fire({
                  icon: 'error',
                  title: 'Error !!!',
                  text: response.msg
                });
              } else {
                toast.error("Something went wrong !!!", ToastObj);
              }
            });
        } catch (error) {
          toast.error("Something went wrong !!!", ToastObj);
          console.error('Error Creating Balance:', error);
        }
      }
    });
  }

  const handleLastBalance = async (event, item) => {
    event.preventDefault();
    try {
      const payload = await enCreption({ cust_id: item.id, vendor_id: auth_user.id });
      await Api.post(`lastBalance`, payload)
        .then(res => {
          const response = res.data;
          if (response.status === "success") {
            const data = response.items;
            let tableRows = '';
            if (data.length > 0) {
              tableRows = data.map(item => `
                <tr>
                  <td>${item.id}</td>
                  <td>${item.username}</td>
                  <td>${item.debit}</td>
                  <td>${item.credit}</td>
                  <td>${item.date}</td>
                </tr>
              `).join('');
            } else {
              tableRows = `
                <tr>
                  <td colspan="3" class="text-center text-muted">No data available</td>
                </tr>
              `;
            }
            Swal.fire({
              title: 'Last 25 Transactions',
              width: '60%',
              html: `<div class="container-fluid">
                        <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                          <thead class="table-light">
                            <tr>
                              <th>ID</th>
                              <th>Username</th>
                              <th>Add Balance</th>
                              <th>Return Balance</th>
                              <th>Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            ${tableRows}
                          </tbody>
                        </table>
                      </div>
                    </div>`,
              confirmButtonText: 'Okay',
              allowOutsideClick: false,
            });
          } else if (response.status === "error") {
            Swal.fire({
              icon: 'info',
              title: 'Info !!!',
              text: response.msg
            });
          } else {
            toast.error("Something went wrong !!!", ToastObj);
          }
        });
    } catch (error) {
      toast.error("Something went wrong !!!", ToastObj);
      console.error('Error Creating Balance:', error);
    }
  }

  return (
    <>
      <main>
        <Navigation auth_user={auth_user} />
        <section>
          <div className="container mt-4">
            <h2 className="mb-1 text-center">Customers</h2>
            <div className="mb-1">
              <div className="row justify-content-center">
                <div className="col-md-3 mb-2">
                  <label htmlFor="search" className="form-label">Search Customers</label>
                  <input
                    type="text"
                    placeholder='username, name, mobile, email'
                    className="form-control"
                    id="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-2">
                  <label htmlFor="status" className="form-label">Status</label>
                  <select
                    className="form-control"
                    id="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="1">Active</option>
                    <option value="0">Deactive</option>
                  </select>
                </div>
                <div className="col-md-3 d-flex justify-content-center align-items-end mb-2">
                  <button
                    className="btn btn-primary me-2"
                    onClick={handleSearchFilter}
                  >
                    Search
                    {loading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleClearFilter}
                  >
                    Clear
                  </button>
                </div>
                <div className="col-md-3 d-flex justify-content-center align-items-end mb-2">
                  <button
                    className="btn btn-primary me-2"
                    onClick={handleAddCustomer}
                  >
                    Add Customers
                    {loading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                  </button>
                </div>
              </div>
            </div>

            <div className="table-responsive" style={{ maxHeight: '50vh' }}>
              <table className="table table-striped table-bordered">
                <thead className="table-light">
                  <tr>
                    <th>ID</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Balance</th>
                    <th>Last Login</th>
                    <th>Date & Time</th>
                    <th>Status</th>
                    <th colSpan={4}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.username}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.mobile}</td>
                          <td>{item.balance}</td>
                          <td>{item.last_login}</td>
                          <td>{item.date}</td>
                          <td>{item.status}</td>
                          <td><button
                            className="btn btn-primary me-2"
                            onClick={(e) => { e.preventDefault(); handleAddCustomer(e, item) }}
                          ><i className='fa fa-pencil' /></button>
                          </td>
                          <td><button
                            className="btn btn-success me-2"
                            onClick={(e) => { e.preventDefault(); handleAddBalance(e, item) }}
                          ><i className='fa fa-inr' /></button>
                          </td>
                          <td><button
                            className="btn btn-info me-2"
                            onClick={(e) => { e.preventDefault(); handleReturnBalance(e, item) }}
                          ><i className='fa fa-retweet' /></button>
                          </td>
                          <td><button
                            className="btn btn-warning me-2"
                            onClick={(e) => { e.preventDefault(); handleLastBalance(e, item) }}
                          ><i className='fa fa-bank' /></button>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center text-muted">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-center mt-0">
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>

          </div>
        </section>
      </main>
    </>
  )
}